<template>
  <div class="row" @click="onClickOutside">
    <SingularTaskLayout
      :title="$t('tasks.declareDeliveryTask.header.title')"
      :task="currentTask"
      :is-loading-task="loading"
      :validate-task-before-complete-hook="validateTaskHook"
      :requests-target-businesses-ids="[supplier.id, task.businessId]"
      @complete-task="completedTask"
      @skip-task="skipTask"
    >
      <template #content>
        <div class="details-container p-6 mb-7">
          <div class="d-flex flex-row align-items-center">
            <el-popover :value="contactPopover" trigger="manual" placement="bottom" popper-class="p-0">
              <ContactCard
                v-if="contactUser"
                :user="contactUser"
                :customer="business"
                :supplier="supplier"
                :token="token"
              />
              <template #reference>
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: business?.id } })"
                >
                  <h3>{{ business?.name }}</h3>
                </Button>
                <ArrowLeftIcon class="arrow-left-icon" />
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="
                    openContactPopover({
                      type: 'supplier',
                      payload: { worksAt: supplier?.id, businessId: business?.id },
                    })
                  "
                >
                  <h3>{{ supplier?.name }}</h3>
                </Button>
              </template>
            </el-popover>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.declareDeliveryTask.details.reconciliationsInfo') }}</p>
            <Button
              v-for="reconciliation in reconciliations"
              :key="reconciliation.id"
              type="link"
              class="text-typography-primary p-0 text-decoration-underline"
              @click="handleReconciliationDateClick(reconciliation.id)"
            >
              {{ formatReconciliationDate(reconciliation.periodStart, reconciliation.periodEnd) }}
            </Button>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.declareDeliveryTask.details.reason') }}</p>
            <p>{{ $t(taskReason) }}</p>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.declareDeliveryTask.details.comments') }}</p>
            <p>{{ taskComment }}</p>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.declareDeliveryTask.details.reconciliationSettings') }}</p>
            <div class="d-flex gap-2 align-items-center">
              <div v-if="reconciliationTemplate.note">
                <p v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</p>
                <p v-else style="width: 552px">
                  <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                </p>
              </div>
              <div>
                <Button type="link" class="text-typography-primary p-0 link" @click="openReconciliationSettings"
                  >{{ $t('tasks.declareDeliveryTask.details.showSettings') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-6">
          <div class="document">
            <div v-if="selectedDocument">
              <div
                class="document-replicas d-flex align-items-center mb-4"
                :class="replicates.length > 1 ? ['has-replicates', 'gap-2'] : ''"
              >
                <span @click="triggerDropdownClick">
                  <ReplicatesDocumentTag v-if="replicates.length > 1" :counter="replicates.length" />
                </span>
                <el-dropdown
                  ref="dropdownRef"
                  trigger="click"
                  :disabled="replicates.length < 2"
                  @command="handleReplicaClick"
                >
                  <div class="d-flex">
                    <span class="replicas-drop-text">
                      {{ $t(`document.exports.schema.type.shortName.${selectedDocument.type}`) }}
                      {{ selectedDocument.documentNumber }}
                    </span>
                    <ChevronIcon v-if="replicates.length > 1" class="chevron-down-icon" color="#71767D" />
                  </div>
                  <el-dropdown-menu slot="dropdown" class="dropdown-select-width" :disabled="replicates.length < 2">
                    <el-dropdown-item
                      v-for="replica in replicates"
                      :key="replica.id"
                      :disabled="replica.id === selectedDocument.id"
                      :command="replica.id"
                    >
                      {{ $t('document.modal.ModalTitle.documentRecordedAt') }}
                      {{ formatDateNumbers(replica.recordedAt) }}
                      {{ replica.isCurrent ? ' - ' + $t('document.modal.ModalTitle.currentDocument') : '' }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="document-details px-4 d-flex align-items-center mb-3 gap-4">
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.reference') }}:</span>
                  <span class="text-underline-hover text-primary" @click="handleDocumentRefClick(selectedDocument.id)">
                    {{ $t(`document.exports.schema.type.shortName.${selectedDocument.type}`) }}
                    {{ selectedDocument.documentNumber }}
                  </span>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.documentType') }}:</span>
                  <span>{{ $t(`document.exports.schema.type.shortName.${selectedDocument.type}`) }}</span>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.date') }}:</span>
                  <span>{{ formatDateNumbers(selectedDocument.issueDate || selectedDocument.deliveryDate) }}</span>
                </div>
              </div>
              <div class="document-viewer">
                <PdfViewer v-if="selectedDocument.filePathUrl" :src="selectedDocument.filePathUrl" />
              </div>
            </div>
          </div>
          <div class="decision mt-5">
            <div v-if="!loading">
              <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="deliveries-orders-tabs" />
              <el-form ref="form" :model="formData" :show-message="false">
                <div v-show="activeTab === 0">
                  <div class="deliveries-table-wrapper">
                    <Table
                      v-loading="loading"
                      :data="deliveredTableData"
                      :columns="mainColumns"
                      show-index
                      :hover="false"
                      expandable
                      custom-class="deliveries-table"
                    >
                      <template #header-supplierDeliveredQuantity="{ column: { header } }">
                        <div>
                          {{ header }}
                          <small class="d-block text-muted">
                            {{ $t('delivery.goodsExchangeRecording.headers.supplierDocumentation') }}
                          </small>
                        </div>
                      </template>
                      <template #header-restaurantDeliveredQuantity="{ column: { header } }">
                        <div>
                          {{ header }}
                          <small class="d-block text-muted">
                            {{ $t('delivery.goodsExchangeRecording.headers.restaurantDocumentation') }}
                          </small>
                        </div>
                      </template>
                      <template #cell-supplierDeliveredQuantity="{ rowData: { productId, associated } }">
                        <el-form-item
                          v-if="taskReason === TASK_REASONS.delivery_replica_quantities"
                          :prop="`deliveredQuantities.supplier.${productId}`"
                          :rules="inputNumberRules"
                          class="m-0"
                        >
                          <el-input
                            v-model.number="formData.deliveredQuantities.supplier[productId]"
                            type="number"
                            size="mini"
                            :style="{ width: '70%' }"
                            @change="updateNested(productId, associated, DELIVERY_BUSINESS.SUPPLIER)"
                          />
                        </el-form-item>
                        <div v-else>
                          {{ formData.deliveredQuantities.supplier[productId] }}
                        </div>
                      </template>
                      <template #cell-restaurantDeliveredQuantity="{ rowData: { productId, associated } }">
                        <el-form-item
                          :prop="`deliveredQuantities.business.${productId}`"
                          :rules="inputNumberRules"
                          class="m-0"
                        >
                          <el-input
                            v-model.number="formData.deliveredQuantities.business[productId]"
                            type="number"
                            size="mini"
                            :style="{ width: '70%' }"
                            @change="updateNested(productId, associated, DELIVERY_BUSINESS.RESTAURANT)"
                          />
                        </el-form-item>
                      </template>

                      <template #expandable-content="{ rowData: { productId: parentProductId, associated } }">
                        <Table :data="associated" :columns="nestedColumns" :hover="false" class="nested-table">
                          <template #cell-supplierDeliveredQuantity="{ rowData: { productId } }">
                            <el-form-item
                              v-if="taskReason === TASK_REASONS.delivery_replica_quantities"
                              :prop="`deliveredQuantities.supplier.${parentProductId}_${productId}`"
                              :rules="inputNumberRules"
                              class="m-0"
                            >
                              <el-input
                                v-model.number="
                                  formData.deliveredQuantities.supplier[`${parentProductId}_${productId}`]
                                "
                                type="number"
                                size="mini"
                                :style="{ width: '70%' }"
                              />
                            </el-form-item>
                            <div v-else>
                              {{ formData.deliveredQuantities.supplier[`${parentProductId}_${productId}`] }}
                            </div>
                          </template>
                          <template #cell-restaurantDeliveredQuantity="{ rowData: { productId } }">
                            <el-form-item
                              :prop="`deliveredQuantities.business.${parentProductId}_${productId}`"
                              :rules="inputNumberRules"
                              class="m-0"
                            >
                              <el-input
                                v-model.number="
                                  formData.deliveredQuantities.business[`${parentProductId}_${productId}`]
                                "
                                type="number"
                                size="mini"
                                :style="{ width: '70%' }"
                              />
                            </el-form-item>
                          </template>
                        </Table>
                      </template>
                    </Table>
                  </div>

                  <div class="py-4 bg-white add-delivery-item">
                    <Button
                      type="secondary"
                      class="mx-2"
                      @click="selectedCreateType = ADD_DELIVERY_ITEM_TYPE[activeTab]"
                    >
                      <BoldPlusIcon :size="20" />
                      {{
                        activeTab === 0
                          ? $t('delivery.goodsExchangeRecording.actions.createDeliveredProduct')
                          : $t('delivery.goodsExchangeRecording.actions.createReturnedProduct')
                      }}
                    </Button>
                  </div>
                </div>
                <div v-show="activeTab === 1">
                  <div class="returns-table-wrapper">
                    <Table
                      v-loading="loading"
                      :data="returnedTableData"
                      :columns="mainColumns"
                      show-index
                      :hover="false"
                      expandable
                      custom-class="deliveries-table"
                    >
                      <template #header-supplierDeliveredQuantity="{ column: { header } }">
                        <div>
                          {{ header }}
                          <small class="d-block text-muted">
                            {{ $t('delivery.goodsExchangeRecording.headers.supplierDocumentation') }}
                          </small>
                        </div>
                      </template>
                      <template #header-restaurantDeliveredQuantity="{ column: { header } }">
                        <div>
                          {{ header }}
                          <small class="d-block text-muted">
                            {{ $t('delivery.goodsExchangeRecording.headers.restaurantDocumentation') }}
                          </small>
                        </div>
                      </template>
                      <template #cell-supplierDeliveredQuantity="{ rowData: { productId, associated } }">
                        <el-form-item
                          v-if="taskReason === TASK_REASONS.delivery_replica_quantities"
                          :prop="`returnedQuantities.supplier.${productId}`"
                          :rules="inputNumberRules"
                          class="m-0"
                        >
                          <el-input
                            v-model.number="formData.returnedQuantities.supplier[productId]"
                            type="number"
                            size="mini"
                            :style="{ width: '70%' }"
                            @change="updateNested(productId, associated, DELIVERY_BUSINESS.SUPPLIER)"
                          />
                        </el-form-item>
                        <div v-else>
                          {{ formData.returnedQuantities.supplier[productId] }}
                        </div>
                      </template>
                      <template #cell-restaurantDeliveredQuantity="{ rowData: { productId, associated } }">
                        <el-form-item
                          :prop="`returnedQuantities.business.${productId}`"
                          :rules="inputNumberRules"
                          class="m-0"
                        >
                          <el-input
                            v-model.number="formData.returnedQuantities.business[productId]"
                            type="number"
                            size="mini"
                            :style="{ width: '70%' }"
                            @change="updateNested(productId, associated, DELIVERY_BUSINESS.RESTAURANT)"
                          />
                        </el-form-item>
                      </template>

                      <template #expandable-content="{ rowData: { productId: parentProductId, associated } }">
                        <Table :data="associated" :columns="nestedColumns" :hover="false" class="nested-table">
                          <template #cell-supplierDeliveredQuantity="{ rowData: { productId } }">
                            <el-form-item
                              v-if="taskReason === TASK_REASONS.delivery_replica_quantities"
                              :prop="`returnedQuantities.supplier.${parentProductId}_${productId}`"
                              :rules="inputNumberRules"
                              class="m-0"
                            >
                              <el-input
                                v-model.number="formData.returnedQuantities.supplier[`${parentProductId}_${productId}`]"
                                type="number"
                                size="mini"
                                :style="{ width: '70%' }"
                              />
                            </el-form-item>
                            <div v-else>
                              {{ formData.returnedQuantities.supplier[`${parentProductId}_${productId}`] }}
                            </div>
                          </template>
                          <template #cell-restaurantDeliveredQuantity="{ rowData: { productId } }">
                            <el-form-item
                              :prop="`returnedQuantities.business.${parentProductId}_${productId}`"
                              :rules="inputNumberRules"
                              class="m-0"
                            >
                              <el-input
                                v-model.number="formData.returnedQuantities.business[`${parentProductId}_${productId}`]"
                                type="number"
                                size="mini"
                                :style="{ width: '70%' }"
                              />
                            </el-form-item>
                          </template>
                        </Table>
                      </template>
                    </Table>
                  </div>

                  <div class="py-4 bg-white add-delivery-item">
                    <Button
                      type="secondary"
                      class="mx-2"
                      @click="selectedCreateType = ADD_DELIVERY_ITEM_TYPE[activeTab]"
                    >
                      <BoldPlusIcon :size="20" />
                      {{
                        activeTab === 0
                          ? $t('delivery.goodsExchangeRecording.actions.createDeliveredProduct')
                          : $t('delivery.goodsExchangeRecording.actions.createReturnedProduct')
                      }}
                    </Button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>

    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="handleChatOpen"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="business"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
    <AddDeliveryItemModal
      v-if="selectedCreateType"
      :products="supplierProducts"
      :type="selectedCreateType"
      @close="selectedCreateType = null"
      @item-added="addDelivery($event)"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import { isNil, reject } from 'ramda';

import { Button, TruncatedText, Tabs, Table } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import {
  useReconciliationTemplate,
  useReconciliations,
  ReconciliationModal,
  ReconciliationSettingsModal,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
} from '@/modules/reconciliation';
import { DocumentModal } from '@/modules/documentModal';
import { useSupplier } from '@/modules/suppliers';
import { ContactCard } from '@/modules/contact';
import { useChatModal } from '@/modules/chatModal';
import { ArrowLeftIcon, BoldPlusIcon, ChevronIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { PdfViewer } from '@/modules/core';
import { useProductsNew } from '@/modules/product/compositions/products';
import { AddDeliveryItemModal } from '@/modules/delivery';

import { SingularTaskLayout } from './components';
import { useBusinessById, useDeliveries, useDelivery, useDeliveryPatch, useDocument } from './composition';
import ReplicatesDocumentTag from '@/modules/documentModal/ReplicatesDocumentTag.vue';

export default {
  name: 'DeclareDeliveryTask',
  components: {
    SingularTaskLayout,
    ContactCard,
    Button,
    TruncatedText,
    ArrowLeftIcon,
    BoldPlusIcon,
    ChevronIcon,
    ReconciliationModal,
    ReconciliationSettingsModal,
    DocumentModal,
    AddDeliveryItemModal,
    PdfViewer,
    Tabs,
    Table,
    ReplicatesDocumentTag,
  },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  emits: ['on-task-complete', 'on-task-skip'],
  setup(props) {
    const { token } = useTenancy();
    const document = ref(props.task.delivery.document);
    const task = ref(props.task);

    watch(
      () => props.task,
      (newTask, oldTask) => {
        if (
          newTask.id !== oldTask?.id ||
          newTask.reviewRequired !== oldTask?.reviewRequired ||
          newTask.data !== oldTask?.data
        ) {
          task.value = newTask;
          document.value = newTask.delivery.document;
        }
      }
    );
    const { products, loading: productsLoading } = useProductsNew(computed(() => document.value?.supplierId));
    const { delivery: businessDelivery, loading: businessDeliveryLoading } = useDelivery(
      computed(() => ({ id: task.value.data?.deliveryId })),
      computed(() => ({
        enabled: !!task.value,
      }))
    );

    const { deliveries: supplierDeliveries, loading: supplierDeliveryLoading } = useDeliveries(
      computed(() => ({
        businessId: document.value.supplierId,
        supplierId: document.value.supplierId,
        date: DateTime.fromJSDate(new Date(document.value?.issueDate || document.value?.deliveryDate)).toISODate(),
      })),
      computed(() => ({
        enabled: !!document.value,
      }))
    );

    const supplierDelivery = computed(() =>
      supplierDeliveries.value?.find((delivery) =>
        delivery.eventReferences
          .map(({ documentId }) => documentId)
          .includes(businessDelivery.value?.eventReferences[0].documentId)
      )
    );

    const { reconciliations, loading: reconciliationsLoading } = useReconciliations(
      computed(() => ({
        businessId: document.value.businessId,
        supplierId: document.value.supplierId,
        fromPeriodDate: DateTime.fromJSDate(new Date(document.value?.issueDate || document.value?.deliveryDate))
          .startOf('month')
          .toISODate(),
        toPeriodDate: DateTime.fromJSDate(new Date(document.value?.issueDate || document.value?.deliveryDate))
          .endOf('month')
          .toISODate(),
      })),
      computed(() => ({
        enabled: !!document.value,
      }))
    );

    const {
      reconciliationTemplate,
      refetch: reconciliationTemplateRefetch,
      loading: reconciliationTemplateLoading,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: document.value?.businessId,
        supplierId: document.value?.supplierId,
      }))
    );
    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const { supplier, loading: supplierLoading } = useSupplier(computed(() => document.value?.supplierId));
    const { business, loading: businessLoading } = useBusinessById(computed(() => document.value?.businessId));

    const { document: documentWithReplicas } = useDocument(
      computed(() => ({
        id: document.value.id,
      })),
      computed(() => ({
        fetchPolicy: 'no-cache',
        enabled: !!document.value,
      }))
    );

    const replicates = computed(() => {
      if (!documentWithReplicas.value) return [];
      const replicates = (documentWithReplicas.value.replicates || []).map((d) => ({
        id: d.id,
        recordedAt: d.recordedAt,
        type: d.type,
        documentNumber: d.documentNumber,
        issueDate: d.issueDate,
        deliveryDate: d.deliveryDate,
        filePathUrl: d.filePathUrl,
      }));
      replicates.push({
        id: documentWithReplicas.value.id,
        recordedAt: documentWithReplicas.value.recordedAt,
        type: documentWithReplicas.value.type,
        documentNumber: documentWithReplicas.value.documentNumber,
        issueDate: documentWithReplicas.value.issueDate,
        deliveryDate: documentWithReplicas.value.deliveryDate,
        filePathUrl: documentWithReplicas.value.filePathUrl,
      });
      replicates.sort((a, b) => (a.recordedAt < b.recordedAt ? -1 : 1));
      return replicates;
    });
    const selectedDocument = ref();

    const formData = reactive({
      deliveredQuantities: {
        supplier: {},
        business: {},
      },
      returnedQuantities: {
        supplier: {},
        business: {},
      },
    });

    watch(replicates, (replicates) => {
      selectedDocument.value = replicates[replicates.length - 1];
    });

    watch([businessDelivery, supplierDelivery, products], ([businessDelivery, supplierDelivery, products]) => {
      const businessDeliveredQuantities = {};
      const businessReturnedQuantities = {};
      const supplierDeliveredQuantities = {};
      const supplierReturnedQuantities = {};

      businessDelivery?.products?.forEach(({ productId, quantity, items }) => {
        if (quantity >= 0) businessDeliveredQuantities[productId] = quantity;
        else businessReturnedQuantities[productId] = Math.abs(quantity);
        items?.forEach(({ productId: nestedProductId, quantity: nestedQuantity }) => {
          if (nestedQuantity >= 0) businessDeliveredQuantities[`${productId}_${nestedProductId}`] = nestedQuantity;
          else businessReturnedQuantities[`${productId}_${nestedProductId}`] = Math.abs(nestedQuantity);
        });
        if (!items) {
          const itemProduct = products?.find((product) => product.id === productId);
          itemProduct?.associatedProducts?.forEach((associatedProduct) => {
            if (isNil(businessDeliveredQuantities[`${productId}_${associatedProduct.productId}`])) {
              businessDeliveredQuantities[`${productId}_${associatedProduct.productId}`] = 0;
            }
            if (isNil(businessReturnedQuantities[`${productId}_${associatedProduct.productId}`])) {
              businessReturnedQuantities[`${productId}_${associatedProduct.productId}`] = 0;
            }
          });
        }
      });

      supplierDelivery?.products?.forEach(({ productId, quantity, items }) => {
        if (quantity >= 0) supplierDeliveredQuantities[productId] = quantity;
        else supplierReturnedQuantities[productId] = Math.abs(quantity);
        items?.forEach(({ productId: nestedProductId, quantity: nestedQuantity }) => {
          if (nestedQuantity >= 0) supplierDeliveredQuantities[`${productId}_${nestedProductId}`] = nestedQuantity;
          else supplierReturnedQuantities[`${productId}_${nestedProductId}`] = Math.abs(nestedQuantity);
        });
      });

      formData.deliveredQuantities.business = businessDeliveredQuantities;
      formData.returnedQuantities.business = businessReturnedQuantities;
      formData.deliveredQuantities.supplier = supplierDeliveredQuantities;
      formData.returnedQuantities.supplier = supplierReturnedQuantities;
    });

    const { closeChatModal, openChatModal } = useChatModal();

    const { patchDelivery } = useDeliveryPatch();

    const loading = computed(
      () =>
        businessDeliveryLoading.value ||
        supplierDeliveryLoading.value ||
        reconciliationsLoading.value ||
        reconciliationTemplateLoading.value ||
        supplierLoading.value ||
        businessLoading.value ||
        productsLoading.value
    );

    return {
      loading,
      token,
      business,
      supplier,
      currentTask: task,
      document,
      products,
      businessDelivery,
      supplierDelivery,
      reconciliations,
      reconciliationTemplate,
      formData,
      replicates,
      selectedDocument,
      reconciliationTemplateRefetch,
      closeChatModal,
      openChatModal,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      patchDelivery,
    };
  },
  data() {
    const ITEM_TYPE = {
      DELIVERED: 'delivered',
      RETURNED: 'returned',
    };

    const ADD_DELIVERY_ITEM_TYPE = {
      0: ITEM_TYPE.DELIVERED,
      1: ITEM_TYPE.RETURNED,
    };

    const DELIVERY_BUSINESS = {
      RESTAURANT: 'business',
      SUPPLIER: 'supplier',
    };

    return {
      ITEM_TYPE,
      ADD_DELIVERY_ITEM_TYPE,
      DELIVERY_BUSINESS,
      VALIDATION_ERRORS: {
        QUANTITY_ITEMS_REQUIRED: 'tasks.declareDeliveryTask.decision.declareDeliveryItemsRequired',
        QUANTITY_ITEM_REQUIRED: 'tasks.declareDeliveryTask.decision.declareDeliveryItemRequired',
      },
      TABLE_HEADERS: {
        SKU: 'sku',
        PRODUCT_NAME: 'name',
        SUPPLIER_DELIVERED_QUANTITY: 'supplierDeliveredQuantity',
        RESTAURANT_DELIVERED_QUANTITY: 'restaurantDeliveredQuantity',
      },
      TASK_REASONS: {
        delivery_declare: 'tasks.declareDeliveryTask.details.reasonDetails.deliveryDeclare',
        delivery_replica_declare: 'tasks.declareDeliveryTask.details.reasonDetails.deliveryDeclareReplica',
        invoice_declare: 'tasks.declareDeliveryTask.details.reasonDetails.invoiceDeclare',
        invoice_replica_declare: 'tasks.declareDeliveryTask.details.reasonDetails.invoiceDeclareReplica',
        delivery_replica_quantities: 'tasks.declareDeliveryTask.details.reasonDetails.deliveryReplicaQuantities',
      },
      contactUser: null,
      contactPopover: false,
      displayedDocumentId: null,
      reconciliationTemplateParams: null,
      pickedReconciliationId: null,
      dropdownRef: null,
      tabs: [
        { text: this.$t('tasks.declareDeliveryTask.decision.tabs.deliveredProducts') },
        { text: this.$t('tasks.declareDeliveryTask.decision.tabs.returnedProducts') },
      ],
      activeTab: 0,
      selectedCreateType: 0,
      inputNumberRules: [
        {
          required: true,
          type: 'number',
          min: 0,
        },
      ],
    };
  },
  computed: {
    taskComment() {
      return this.currentTask.data?.comment ?? '';
    },
    taskReason() {
      if (!this.loading) {
        const { type } = this.document;
        const { isReplica, hasDeclaration, hasQuantityDifferences } = this.currentTask?.data || {};

        if (hasQuantityDifferences) {
          return this.TASK_REASONS.delivery_replica_quantities;
        } else if (type === 'deliveryNote' && hasDeclaration) {
          if (isReplica) {
            return this.TASK_REASONS.delivery_replica_declare;
          }
          return this.TASK_REASONS.delivery_declare;
        } else if (type === 'invoice' && hasDeclaration) {
          if (isReplica) {
            return this.TASK_REASONS.invoice_replica_declare;
          }
          return this.TASK_REASONS.invoice_declare;
        }
      }

      return null;
    },
    isSupplierTemplateDefined() {
      return !!this.reconciliationTemplate?.supplierId;
    },
    deliveredTableData() {
      const deliveryItemsProperties = () => {
        return Object.keys(this.formData.deliveredQuantities.business)
          .filter((key) => key.split('_').length === 1)
          .map((productId) => {
            const deliveryProduct = this.businessDelivery?.products?.find(
              ({ productId: deliveryProductId, quantity }) => deliveryProductId === productId && quantity >= 0
            );
            const product = this.products.find(({ id }) => id === productId);

            const deliveryAssociatedItems =
              deliveryProduct?.items?.map(({ productId }) => {
                const associatedProduct = this.products?.find(({ id }) => id === productId);
                return {
                  productId,
                  sku: associatedProduct?.sku,
                  name: associatedProduct?.name,
                };
              }) ||
              product?.associatedProducts?.map(({ productId }) => {
                const associatedProduct = this.products?.find(({ id }) => id === productId);
                return {
                  productId,
                  sku: associatedProduct?.sku,
                  name: associatedProduct?.name,
                };
              });

            return {
              productId,
              sku: product?.sku,
              name: product?.name,
              associated: deliveryAssociatedItems,
            };
          });
      };
      return deliveryItemsProperties()?.map(({ productId, sku, name, associated }) => {
        return {
          productId,
          associated,
          expandable: associated?.length,
          [this.TABLE_HEADERS.SKU]: sku,
          [this.TABLE_HEADERS.PRODUCT_NAME]: name,
        };
      });
    },
    returnedTableData() {
      const returnedItemsProperties = () => {
        return Object.keys(this.formData.returnedQuantities.business)
          .filter((key) => key.split('_').length === 1)
          .map((productId) => {
            const deliveryProduct = this.businessDelivery?.products?.find(
              ({ productId: deliveryProductId, quantity }) => deliveryProductId === productId && quantity < 0
            );
            const product = this.products.find(({ id }) => id === productId);

            const deliveryAssociatedItems =
              deliveryProduct?.items?.map(({ productId }) => {
                const associatedProduct = this.products?.find(({ id }) => id === productId);
                return {
                  productId,
                  sku: associatedProduct?.sku,
                  name: associatedProduct?.name,
                };
              }) ||
              product?.associatedProducts?.map(({ productId }) => {
                const associatedProduct = this.products?.find(({ id }) => id === productId);
                return {
                  productId,
                  sku: associatedProduct?.sku,
                  name: associatedProduct?.name,
                };
              });

            return {
              productId,
              sku: product?.sku,
              name: product?.name,
              associated: deliveryAssociatedItems,
            };
          });
      };
      return returnedItemsProperties()?.map(({ productId, sku, name, associated }) => {
        return {
          productId,
          associated,
          expandable: associated?.length,
          [this.TABLE_HEADERS.SKU]: sku,
          [this.TABLE_HEADERS.PRODUCT_NAME]: name,
        };
      });
    },
    mainColumns() {
      return [
        {
          header: this.$t('sku'),
          key: this.TABLE_HEADERS.SKU,
          width: '15%',
        },
        {
          header: this.$t('product.product'),
          key: this.TABLE_HEADERS.PRODUCT_NAME,
        },
        {
          header:
            this.activeTab === 0
              ? this.$t('tasks.declareDeliveryTask.decision.deliveryTable.deliveredQuantity')
              : this.$t('tasks.declareDeliveryTask.decision.returnTable.returnedQuantity'),
          key: this.TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY,
          width: '25%',
        },
        {
          header:
            this.activeTab === 0
              ? this.$t('tasks.declareDeliveryTask.decision.deliveryTable.deliveredQuantity')
              : this.$t('tasks.declareDeliveryTask.decision.returnTable.returnedQuantity'),
          key: this.TABLE_HEADERS.RESTAURANT_DELIVERED_QUANTITY,
          width: '25%',
        },
      ];
    },
    nestedColumns() {
      return [
        { key: 'empty', width: '85px' },
        {
          key: this.TABLE_HEADERS.SKU,
          width: '15%',
        },
        {
          key: this.TABLE_HEADERS.PRODUCT_NAME,
        },
        {
          key: this.TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY,
          width: '25%',
        },
        {
          key: this.TABLE_HEADERS.RESTAURANT_DELIVERED_QUANTITY,
          width: '25%',
        },
      ];
    },
    supplierProducts() {
      return this.selectedCreateType === this.ADD_DELIVERY_ITEM_TYPE[0]
        ? this.products.filter(({ id }) => this.deliveredTableData.every(({ productId }) => id !== productId))
        : this.products.filter(({ id }) => this.returnedTableData.every(({ productId }) => id !== productId));
    },
  },
  methods: {
    async validateTaskHook() {
      const warnings = [],
        errors = [];

      const buildPatchProduct = (productId, customer, data, itemType) => {
        const isReturn = itemType === this.ITEM_TYPE.RETURNED;
        const deliveryProductQuantity = isReturn ? data[customer][productId] * -1 : data[customer][productId];

        if (deliveryProductQuantity !== 0) {
          const associatedItems = Object.keys(data[customer])
            .filter((key) => key.startsWith(`${productId}_`) && !!data[customer][key])
            .map((key) => {
              return {
                productId: key.split('_')[1],
                quantity: data[customer][key],
              };
            });

          return {
            productId,
            quantity: deliveryProductQuantity,
            items: associatedItems,
          };
        }
      };

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const patchBusinessProducts = [];
          const patchSupplierProducts = [];

          if (Object.keys(this.formData.deliveredQuantities.business).length !== 0) {
            Object.keys(this.formData.deliveredQuantities.business)
              .filter((key) => key.split('_').length === 1)
              .forEach(async (productId) => {
                patchBusinessProducts.push(
                  buildPatchProduct(productId, this.DELIVERY_BUSINESS.RESTAURANT, this.formData.deliveredQuantities)
                );
                patchSupplierProducts.push(
                  buildPatchProduct(productId, this.DELIVERY_BUSINESS.SUPPLIER, this.formData.deliveredQuantities)
                );
              });
          }

          if (Object.keys(this.formData.returnedQuantities.business).length !== 0) {
            Object.keys(this.formData.returnedQuantities.business)
              .filter((key) => key.split('_').length === 1)
              .forEach(async (productId) => {
                patchBusinessProducts.push(
                  buildPatchProduct(
                    productId,
                    this.DELIVERY_BUSINESS.RESTAURANT,
                    this.formData.returnedQuantities,
                    this.ITEM_TYPE.RETURNED
                  )
                );
                patchSupplierProducts.push(
                  buildPatchProduct(
                    productId,
                    this.DELIVERY_BUSINESS.SUPPLIER,
                    this.formData.returnedQuantities,
                    this.ITEM_TYPE.RETURNED
                  )
                );
              });
          }

          try {
            const promises = [];

            const businessProducts = reject(isNil, patchBusinessProducts);
            businessProducts.length &&
              promises.push(
                this.patchDelivery({
                  id: this.businessDelivery.id,
                  data: { products: businessProducts },
                })
              );

            if (this.TASK_REASONS.delivery_replica_quantities === this.taskReason) {
              const supplierProducts = reject(isNil, patchSupplierProducts);
              supplierProducts.length &&
                promises.push(
                  this.patchDelivery({
                    id: this.supplierDelivery.id,
                    data: { products: supplierProducts },
                  })
                );
            }
            await Promise.all(promises);

            this.$message.success(this.$t('commons.messages.action.success'));
          } catch (error) {
            errors.push(error);
          }
        }
      });

      return { warnings, errors };
    },
    async completedTask() {
      this.$emit('on-task-complete');
    },
    skipTask() {
      this.$emit('on-task-skip');
    },
    handleReconciliationDateClick(id) {
      this.pickedReconciliationId = id;
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    onClickOutside() {
      this.contactPopover = false;
    },
    formatReconciliationDate(periodStart, periodEnd) {
      if (!periodStart || !periodEnd) return '';
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(periodStart).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: 'numeric',
      });
    },
    openReconciliationSettings() {
      this.reconciliationTemplateParams = {
        supplierId: this.supplier.id,
        template: this.reconciliationTemplate,
      };
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = this.formatDateShort(reconciliationPeriod, isDaily);
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        reconciliationId,
        formattedPeriod,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: 'numeric',
      });
    },
    handleOpenDocument(documentId) {
      this.displayedDocumentId = documentId;
    },
    formatDateNumbers(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      });
    },
    handleDocumentRefClick(id) {
      this.displayedDocumentId = id;
    },
    updateNested(parentProductId, associatedItems, deliveryBusiness) {
      const productQuantities =
        this.ADD_DELIVERY_ITEM_TYPE[this.activeTab] === this.ITEM_TYPE.DELIVERED
          ? this.formData.deliveredQuantities[deliveryBusiness]
          : this.formData.returnedQuantities[deliveryBusiness];

      const delivery =
        deliveryBusiness === this.DELIVERY_BUSINESS.RESTAURANT ? this.businessDelivery : this.supplierDelivery;

      associatedItems.forEach((item) => {
        const deliveryProduct = delivery.products.find((product) => product.productId === parentProductId);
        const product = this.products.find(({ id }) => id === parentProductId);
        const deliveryItem = deliveryProduct?.items?.find((item) => item.productId === item.productId);
        const associatedProduct = product?.associatedProducts?.find(
          (associatedProduct) => associatedProduct.productId === item.productId
        );

        const deliveryProductQuantity = Math.abs(deliveryProduct.quantity);
        const updatedAssociatedItemQuantity = deliveryProductQuantity
          ? (productQuantities[parentProductId] * Math.abs(deliveryItem?.quantity ?? 0)) / deliveryProductQuantity
          : productQuantities[parentProductId] * Math.abs(associatedProduct?.quantity ?? 0);

        this.$set(productQuantities, `${parentProductId}_${item.productId}`, updatedAssociatedItemQuantity);
      });
    },
    addDelivery(data) {
      if (this.ADD_DELIVERY_ITEM_TYPE[this.activeTab] === this.ITEM_TYPE.DELIVERED) {
        this.$set(this.formData.deliveredQuantities.business, data.productId, data.quantity);
        this.$set(this.formData.deliveredQuantities.supplier, data.productId, 0);
      } else {
        this.$set(this.formData.returnedQuantities.business, data.productId, data.quantity);
        this.$set(this.formData.returnedQuantities.supplier, data.productId, 0);
      }

      const product = this.products.find(({ id }) => id === data.productId);
      product?.associatedProducts?.forEach((item) => {
        const updatedAssociatedItemQuantity = data.quantity * item.quantity;
        if (this.ADD_DELIVERY_ITEM_TYPE[this.activeTab] === this.ITEM_TYPE.DELIVERED) {
          this.$set(
            this.formData.deliveredQuantities.business,
            `${data.productId}_${item.productId}`,
            updatedAssociatedItemQuantity
          );
          this.$set(
            this.formData.deliveredQuantities.supplier,
            `${data.productId}_${item.productId}`,
            updatedAssociatedItemQuantity
          );
        } else {
          this.$set(
            this.formData.returnedQuantities.business,
            `${data.productId}_${item.productId}`,
            updatedAssociatedItemQuantity
          );
          this.$set(
            this.formData.returnedQuantities.supplier,
            `${data.productId}_${item.productId}`,
            updatedAssociatedItemQuantity
          );
        }
      });
      this.selectedCreateType = null;
    },
    triggerDropdownClick() {
      if (this.dropdownRef.visible) {
        this.dropdownRef.hide();
      } else {
        this.dropdownRef.show();
      }
    },
    handleReplicaClick(documentId) {
      this.selectedDocument = this.replicates.find((document) => document.id === documentId);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$secondary: #94989f;
$color-neutral: #f3f3f4;
$validation-error: #cc2d4b;

.details-container {
  border: 1px solid $gray-outline;
  border-radius: 4px;
}
.document {
  flex: 1;
}

.decision {
  flex: 1;
  .el-input {
    width: 275px;
  }

  button {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 4px;
    padding: 0px 16px;
    padding-inline-end: 22px;

    :deep(span) {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  :deep(.radio-group .el-radio__label) {
    color: $black;
  }

  .index {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid $gray-outline;
    font-size: 14px;
  }

  .validation-error {
    .error-message {
      color: $validation-error;
    }

    .el-input {
      width: 265px;
      :deep(input) {
        border-color: $validation-error;
      }
    }
  }
}

.detail {
  color: $typography-secondary;
  width: 160px;
  min-width: 160px;
}

.link {
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.headline-text {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
}

.labelline-text {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}

.document-details {
  width: 100%;
  height: 52px;
  background: #f8fafb;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  font-size: 14px;
}

.text-underline-hover {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.document-viewer {
  height: 600px;
}

.arrow-left-icon {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
}

.questionmark-icon {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
}

:deep(p.d-inline-block.position-absolute) {
  white-space: pre-line !important;
}

.deliveries-orders-tabs {
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  height: 52px;
}

.deliveries-table-wrapper,
.returns-table-wrapper {
  overflow-y: auto;
  max-height: 704px;
}

:deep(.deliveries-table) {
  border-radius: 4px;
  border: 1px solid $gray-outline;

  .expandable-row > td {
    padding: 0px;
  }

  td {
    padding: 0.25rem 1rem;
    vertical-align: middle;
    height: 52px;
  }

  .header-expandable {
    height: 60px;
  }
}
:deep(td.position-relative) {
  padding: 0px 2px;
}
:deep(.nested-table) {
  th {
    padding: 0;
  }
  td {
    background: #ecf0f3;
  }
}

.document-replicas {
  &.has-replicates {
    padding: 0px 6px;
  }

  .replicas-drop-text {
    color: #131416;
    font-size: 20px;
    font-weight: 700;
  }

  :deep(.el-dropdown-selfdefine) {
    align-items: center;
    gap: 4px;
  }
}
</style>
